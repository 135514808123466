<div id="login-page" class="overflow-clip">
    <video
        id="background-video"
        src="https://spoke.zone/videos/SpokeZoneTopVid.mp4"
        type="video/mp4"
        loop
        playsinline
        class="absolute bottom-0 left-0 right-0 top-0 object-cover min-h-full min-w-full max-h-[100vh] max-w-[100vw]"
    ></video>
    <div
        class="absolute h-screen w-screen bg-[url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAH0lEQVQIW2NggAABIFYAYgMgdsAqwIBNgAGbAAM2AQCXWQKBzohaMwAAAABJRU5ErkJggg==)]"
    ></div>
    <div class="mat-typography h-screen">
        <nav
            class="absolute flex h-[44px] w-full flex-row items-center bg-gray-500 uppercase text-white"
        >
            <div
                class="pointer-events-none relative flex h-full w-[20rem] flex-row gap-1 items-center bg-orange-600 p-1 text-xl"
            >
                <img
                    class="rounded-lg"
                    [src]="platformConfigurationService.favicon"
                    [alt]="platformConfigurationService.logoAlt"
                    width="36px"
                />
                <span class="font-bold">
                    {{ platformConfigurationService.getName('bullet') }}
                </span>
            </div>
            <div class="flex grow flex-row justify-end">
                <a
                    class="pr-4 hover:text-orange-600 hover:underline"
                    href="https://spoke.zone"
                    >{{ 'about' | translate }}</a
                >
            </div>
        </nav>
        <form
            [formGroup]="form"
            class="top-[44px] flex h-full flex-col items-center justify-center"
            [class.forgot-password]="showForgotPasswordDialog"
        >
            <mat-card
                class="flex w-[320px] flex-col gap-2 !rounded-2xl p-4 opacity-95"
            >
                <ng-container
                    *ngIf="showForgotPasswordDialog; else loginDialog"
                >
                    <div class="flex flex-col items-center justify-center">
                        <h1>{{ 'forgot_password.title' | translate }}</h1>
                        <div class="pb-3 text-xs">
                            {{ 'forgot_password.desc' | translate }}
                        </div>
                    </div>
                </ng-container>
                <ng-template #loginDialog>
                    <div
                        *ngIf="!showForgotPasswordDialog"
                        class="pointer-events-none flex select-none flex-row justify-center"
                    >
                        <img
                            [alt]="platformConfigurationService.logoAlt"
                            [src]="platformConfigurationService.logo"
                            class="mx-auto mb-6"
                            width="240px"
                            height="220px"
                        />
                    </div>
                </ng-template>

                <div class="flex w-full flex-col">
                    <div class="mat-subtitle-2">
                        {{ 'common.email' | translate }}
                    </div>
                    <sz-input
                        class="[&_input]:w-full"
                        [formControl]="email"
                        [errorMessages]="errorMessages"
                    />
                </div>

                <ng-container
                    *ngIf="!showForgotPasswordDialog; else forgotPassword"
                >
                    <div class="flex flex-col">
                        <div class="mat-subtitle-2">
                            {{ 'login.password' | translate }}
                        </div>
                        <sz-input
                            class="[&_input]:w-full"
                            [formControl]="password"
                            type="password"
                        />
                    </div>
                    <div class="flex flex-row justify-between">
                        <div
                            class="flex flex-row items-center justify-center gap-2 pl-1"
                        >
                            <input
                                type="checkbox"
                                formControlName="rememberMe"
                            />
                            <span>{{ 'login.remember_me' | translate }}</span>
                        </div>
                        <button
                            type="button"
                            class="font-light text-sky-700 hover:cursor-pointer hover:underline"
                            (click)="showForgotPasswordDialog = true"
                        >
                            {{ 'login.forgot_password' | translate }}
                        </button>
                    </div>
                    <sz-button
                        size="large"
                        text="login.sign_in"
                        bgColor="base"
                        (click)="submit()"
                    />
                </ng-container>
                <ng-template #forgotPassword>
                    <sz-dialog-button-bar
                        primaryText="btn.send_email"
                        [primaryDisabled]="email.invalid"
                        (primaryClick)="sendResetEmail()"
                        (secondaryClick)="showForgotPasswordDialog = false"
                    />
                </ng-template>
            </mat-card>
        </form>
    </div>
</div>
